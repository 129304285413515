<template>
  <div id="c-body">
    <CBanner :bannerCont="bannerCont"/>
    <div id="content">
      <p style="
        text-align: center;    
        font-size: 22px;    
        line-height: 50px;"
      >
        发展历程
      </p>
      <p>2018.1.17 西安核创能源科技有限公司在西安高新技术开发区注册成立</p>
      <p>2018.12.1公司与中山大学签署软件开发合同</p>
      <p>2019.06.10公司成为中国核工业集团公司的合格供应商</p>
      <p>公司自主研发的“蒙特卡罗粒子输运计算软件[简称: MCX] V1.0”在国家版权局完成软件著作权登记（登记号：2019SR0799806）</p>
      <p>2019.3.1 公司与国家电投集团科学技术研究院有限公司签署软件开发合同</p>
      <p>2019.9.27 公司研发的LOCUST/SPARK软件系统在我国福清核电基地投入使用</p>
      <p>2019.10.16 公司与中国核电工程有限公司签署软件开发合同</p>
      <p>2019.10.23 公司自主研发的“热谱堆核燃料和反射层组件非均匀及等效均匀化参数计算软件[简称:LOCUST] V1.0”在国家版权局完成软件著作权登记（登记号：2019SR1075691）</p>
      <p>2019.10.23 公司自主研发的“压水堆堆芯三维稳态燃料管理和瞬态时空动力学软件[简称:SPARK] V1.0”在国家版权局完成软件著作权登记（登记号：2019SR1075692）</p>
      <p>2019.11.28 公司自主研发的“先进反应堆物理计算分析软件[简称:SARAX-C] V1.0”在国家版权局完成软件著作权登记（登记号：2019SR1226735）</p>
      <p>2019.11.28 公司自主研发的“核反应堆堆用数据库加工软件[简称:Atlas-C] V1.0”在国家版权局完成软件著作权登记（登记号：2019SR1227297）</p>
      <p>2019.12.1 公司与上海交通大学签署技术服务合同</p>
      <p>2020.3.15 公司与清华大学核能与新能源技术研究院签署技术服务合同</p>
      <p>2020.5.6 公司与中国核电工程有限公司签署软件开发合同</p>
      <p>2020.6.9 公司与中广核工程有限公司签署研发采购合同</p>
      <p>2020.8.1 公司与沈阳飞机设计研究院扬州协同创新研究院有限公司签署技术服务合同</p>
      <p>2020.11.2 公司国家电投集团科学技术研究院有限公司签署技术服务合同  </p>   
    </div>
    <div class="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
import CBanner from '../common/content/CBanner.vue'
import Footer from '../common/Footer'
export default {
  name: "abouts",
  components: {
    'CBanner': CBanner,
    'Footer': Footer
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../../assets/show-banner.png'),
        title: "公司简介",
        txt: "面向复杂的实际工程问题提供解决方案, 做到精心服务全方位掌控, 为攻克卡脖子难题做出自己的贡献坚持以需求为导向，以量身定制技术为核心，以自主攻关为使命",
      },
    }
  }
}
</script>

<style scoped>
div#c-body {
  max-width: 1920px;
  margin: 0 auto;
}
div#content {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 100px;
  color: #323232;
  text-indent: 2em;
  font-size: 14px;
  line-height: 240%;
  text-align: justify;
  text-justify: distribute;
}
div.footer {
  position: relative;
  top: 100px;
}
</style>
