<template>
  <div>
    <img :src="this.bannerCont.pic">
    <div id="banner-content">
      <div id="abouts">
        <h4>{{ this.bannerCont.title }}</h4>
        <p>{{ this.bannerCont.txt }}</p>
      </div>
    </div>
    <div id="bread-content">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="bread-item">
          <el-breadcrumb-item
            v-for="item,index in this.breadList" :key="index"
            :to="item.path"
          >
            {{ item.name }}
          </el-breadcrumb-item>
        </div>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>

export default {
  name: "Profile",
  components: {

  },
  props: {
    bannerCont: Object,
  },
  data() {
    return {
    breadList: [] // 路由集合
    };
  },
  watch: {
    $route() {
    this.getBreadcrumb();
    }
  },
  methods: {
    isHome(route) {
    return route.name === "index";
    },
    getBreadcrumb() {
    let matched = this.$route.matched;
    //如果不是首页
    if (!this.isHome(matched[0])) {
      matched = [{ path: "/index", meta: { title: "首页" }, name: "首页" }].concat(matched);
    }
    this.breadList = matched;
    }
  },
  created() {
    this.getBreadcrumb();
  }
}
</script>

<style scoped>
div {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
div > img {
  width: 100%;
}
div > div#banner-content {
  position: relative;
  top: -260px;
  max-width: 1280px;  
  width: 100%;
  margin: 0 auto;
  display: block;
  z-index: 100;
}
div > div#banner-content > div#abouts {
  position: absolute;
  width: 42%;
  float: left;
}
div > div#banner-content > div#abouts > h4 {
  display: block;
  width: 100%;
  padding-bottom: 6px;
  font-size: 1.625rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 2.5rem;
  text-align: left;
}
div > div#banner-content > div#abouts > p {
  font-size: 15px;
  padding-bottom: 0px;
  color: #FFFFFF;
  line-height: 1.5rem;
  opacity: 0.8;
}
/* 面包屑 */
div#bread-content {
  position: relative;
  top: -3px;
  max-width: 1920px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 9.9px 0.1px rgba(136, 99, 255, 0.29);
}
div#bread-content div.bread-item {
  position: relative;
  max-width: 1280px;
  line-height: 3.75rem;
}
</style>